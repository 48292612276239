body{
  min-width: 1170px;
  font-family: 'Underdog', cursive;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-family: 'Underdog', cursive;
}
.navbar {
    border-radius: 0;
}
footer{
    padding: 15px 0;
    text-align: center;
    background-color: #2d5d96;
    color: #fff;
}

a{
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;

}

.message{
  padding: 10px;
}

.buttonMargin{
  margin: 0 10px 20px 0;
}

textarea.form-control{
  height: 250px;
}
.tableWithLinks{
  font-size: 12px;
}

.mainWrap{
  min-height: 870px;
}

.pagination > li > a, .pagination > li > span {
  padding: 3px 6px;
  font-size: 13px;
}

.navbar-brand > img {
    display: inline-block;
}

.navbar-brand .glyphicon {
    top: 4px;
}

.navbar-brand {
    cursor: pointer;
}

table .checkbox, table .radio {
    margin-top: 0;
    margin-bottom: 0;
}

.stage {
    margin: 15px auto;
    perspective: 200px;
}

img.rot {
    width: 180px;
    margin:0 auto;
    -webkit-animation-name: spinner;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 12s;
    animation-name: spinner;  /* указываем на ключевые кадры анимации с названием "spinner" */
    animation-timing-function: linear; /* функция синхронизации, с помощью которой будут происходить преобразования */
    animation-iteration-count: infinite; /* количество повторов анимации. "infinite" - значит бесконечно */
    animation-duration: 12s; /* длительность анимации */
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d; /* указываем что это 3D преобразование */
}

img.rot:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    cursor:pointer;
}

/* WebKit и Opera */
@-webkit-keyframes spinner {
    from {
        -webkit-transform: rotateY(0deg);
    }
    to {
        -webkit-transform: rotateY(-360deg);
    }
}

/* Все остальные браузеры */
@keyframes spinner {
    from {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to {
        -moz-transform: rotateY(-360deg);
        -ms-transform: rotateY(-360deg);
        transform: rotateY(-360deg);
    }
}
/* @keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

span.linksStyle {
    max-width: 700px;
    word-wrap: break-word;
    display: inline-block;
}

.successReport{
  color: red;
}
.errorReport{
  color: green;
}

.reportTable td{
  width: 12%;
}
.reportTable td:last-of-type{
  width: 64%;
}
